import React from "react";
import "./Information.css";

const Information = () => {
  return (
    <div className="box-shadow">
      <div id="industries" className="information-main ">
        <div className="left-information">
          <h1>
            Smart Comfort in all <br />
            the JaiyqDev's offices
          </h1>
          {/* <img
          src={require("../../../assets/images/office-photo.jpg")}
          alt="no office photo"
        /> */}
          <div className="leftContainer">
            <img
              style={{ maxWidth: "300px" }}
              src={require("../../../assets/images/office-1.jpg")}
              alt="office-photo"
            />
            <img
              style={{ maxWidth: "300px" }}
              src={require("../../../assets/images/office-3.jpg")}
              alt="office-photo"
            />
          </div>
        </div>
        <div className="right-information">
          <span style={{ maxWidth: "670px" }}>
            JaiyqDev is a global technology partner that enables the sustained
            success of top-tier organizations and Fortune 500 companies. <br />{" "}
            By delivering value to our partners and clients with the power of
            technology, we inspire confidence that their digital initiatives
            will succeed. They trust us with finding solutions to the toughest
            challenges and breaking constraints on their way to meaningful
            innovation in a dynamic market environment.
          </span>
          {/* <img
          src={require("../../../assets/images/photo-office 2.jpg")}
          alt="no photo office 2"
        /> */}
          <div className="rightContainer">
            <img
              style={{ maxWidth: "300px" }}
              src={require("../../../assets/images/office-4.jpg")}
              alt="no photo office 2"
            />
            <img
              style={{ maxWidth: "300px" }}
              src={require("../../../assets/images/office-5.jpg")}
              alt="no photo office 2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
