import React from "react";
import "./style.css";

const About = () => {
  return (
    <div id="expertise" className="about container">
      <div className="left-block">
        <h2>Who we are</h2>
        <p>
          JaiyqDev is a global technology partner leading change in transforming
          industries <br /> and generating long-lasting value for businesses,
          people, and the global community. <br /> They trust us with finding
          solutions to the toughest challenges and breaking constraints <br />{" "}
          on their way to meaningful innovation in a dynamic market environment.{" "}
          <br /> Based on 20 years of market experience, JaiqDev people-centric
          approach <br /> and passion for client success unite engineers,
          creators, and innovators across Europe, <br /> North America, and the
          Middle East.
        </p>
        <div className="about-inner">
          <div className="about-stats-item">
            <h3>40</h3>
            <p>
              Engineers <span>in-house</span>
            </p>
          </div>
          <div className="about-stats-item">
            <h3>7</h3>
            <p>
              Countries where <span>client are located</span>
            </p>
          </div>

          <div className="about-stats-item">
            <h3>10</h3>
            <p>
              Years of <span>experience</span>
            </p>
          </div>
          <div className="about-stats-item">
            <h3>60%</h3>
            <p>
              Senior <span>engineers</span>
            </p>
          </div>
        </div>
        <div className="bottom-text">
          <h4>Our missions:</h4>
          <p>
            We breath life into great ideas with the power of digital technology
          </p>
        </div>
      </div>
      <div className="right-block">
        <img
          src={require("../../../assets/images/homelogo.png")}
          alt="no photo"
        />
      </div>
    </div>
  );
};

export default About;
