const data = [
  // {
  //   id: 1,
  //   name: 'Kairat',
  //   lname: 'Karzhau',
  //   position: 'Senior Full stack Developer/ Team Lead',
  //   skills: 'Javascript, Typescript, Node.js, MongoDB',
  //   photo:  '#',
  // },
  // {
  //   id: 2,
  //   name: 'Yeldos',
  //   lname: 'Nurlan',
  //   position: 'Senior Full stack Developer/ Team Lead',
  //   skills: 'Javascript, Typescript, Node.js, MongoDB, C++',
  //   photo: '#',
  // },
  {
    id: 3,
    name: 'Aidos',
    lname: 'Suieugali',
    position: 'Senior Full stack Developer/ Team Lead',
    skills: 'React, React Native, Node.js, MongoDB',
    photo: require('../assets/images/Aidos.jpeg'),
  },
  {
    id: 4,
    name: 'Dauren',
    lname: 'Naukhan',
    position: 'Senior Full stack Developer/ Team Lead',
    skills: 'C++, Java, Clouds, AWS, React, Node.js ',
    photo: require('../assets/images/Dauren.jpeg'),
  },
  {
    id: 5,
    name: 'Kenes',
    lname: 'Baimukan',
    position: 'Software Engineer',
    skills: 'React, React Native, Java, Dart, CI/CD, DevOps, Docker',
    photo: require('../assets/images/Kenes.jpeg'),
  },
  {
    id: 6,
    name: 'Yerzhaisang',
    lname: 'Taskali',
    position: 'Software Engineer',
    skills:
      'React Native, Python, SQL, Machine Learning, Deep learning, NLP, Devops, Docker',
    photo: require('../assets/images/Yerzhaisang.jpeg'),
  },
  {
    id: 7,
    name: 'Ruslan',
    lname: 'Muldash',
    position: 'React Native Developer',
    skills: 'React Native, Javascript, Typescript, Redux',
    photo: require('../assets/images/Ruslan.jpeg'),
  },
  {
    id: 8,
    name: 'Bekzat',
    lname: 'Gazezov',
    position: 'React Native Developer',
    skills: 'React Native, Javascript, Typescript, Redux',
    photo: require('../assets/images/Bekzat.jpeg'),
  },
  {
    id: 9,
    name: 'Adil',
    lname: 'Temirgaliyev',
    position: 'Frontend Developer',
    skills: 'React, React Native, Javascript, Typescript',
    photo: require('../assets/images/Adil.jpeg'),
  },
  {
    id: 10,
    name: 'Adilzhan',
    lname: 'Abdigaliyev',
    position: 'Frontend Developer',
    skills: 'React, React Native, Javascript, Typescript',
    photo: require('../assets/images/Adilzhan.jpeg'),
  },
];

export default data;
