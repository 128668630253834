import React from "react";
import "./MainSection.css";
import videoBg from "../../../assets/videos/videoBg.mp4";

const MainSection = () => {
  return (
    <div id="services" className="main-section">
      <div className="main-section-title">
        <h1>"Meet JaiyqDev"</h1>
      </div>
      <video autoPlay loop muted>
        <source src={videoBg} />
      </video>
    </div>
  );
};

export default MainSection;
