import React from "react";
import { Link, useParams } from "react-router-dom";
import projects from "../../../data/projects";
import "./Project.css";
import Footersec from "../Footersec/Footersec";

export const Project = () => {
  const params = useParams();
  const item = projects.find((elem) => {
    return elem.project === params.project && elem;
  });
  console.log("QweRTyuI", item.whatChallengedToDo);
  return (
    <div>
      <Link to="/" className="btn-back">
        <svg
          className="btn-svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          height="30px"
          width="30px"
        >
          <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.42L6.83 13H21v-2z" />
        </svg>
      </Link>
      <div className="project">
        <h1 className="project__title">{item.project}</h1>
        <h2 className="project__text">{item.whatOurClientDo}</h2>
        <img
          src={item.img}
          alt=""
          style={{ maxWidth: "100%", margin: "0 auto" }}
        />
        <div className="project-container">
          <h2 className="project__list-title">Summary:</h2>
          <ul className="project__list">
            {/* <li className="project__list-item">
            <h3>Country</h3>
            <p>{item.country}</p>
          </li>
          <li className="project__list-item">
            <h3>Stacks used</h3>
            <p>{item.stacksUsed}</p>
          </li>
          <li className="project__list-item">
            <h3>Resources used</h3>
            <p>{item.resourcesUsed}</p>
          </li>
          <li className="project__list-item">
            <h3>Work duration</h3>
            <p>{item.duration}</p>
          </li> */}
            <li className="project__list-item">
              <h3>Client</h3>
              <p className="list-item__text">{item.client}</p>
            </li>
            <li className="project__list-item">
              <h3>Country</h3>
              <p className="list-item__text">{item.country}</p>
            </li>
            <li className="project__list-item">
              <h3>Industry</h3>
              <p className="list-item__text">{item.industry}</p>
            </li>
            <li className="project__list-item">
              <h3>Type of service</h3>
              <p className="list-item__text">{item.typeOfService}</p>
            </li>
          </ul>
          <div className="challenge_to-do">
            <h2 className="to-do__title">What we've challenged to do</h2>
            <p>{item.whatChallengedToDo.mainText}</p>
            <div>
              {item.whatChallengedToDo.list &&
                item.whatChallengedToDo.list.map((e) => <p>{e}</p>)}
            </div>
          </div>
          {item.whatDone.mainText && (
            <div className="what__Done">
              <h2 className="what__Done-title">What we've done</h2>
              <p>{item.whatDone.mainText}</p>
              <div>
                {item.whatDone.list.map((e) => (
                  <p>{e}</p>
                ))}
              </div>
            </div>
          )}
          <div className="delivered">
            <h2 className="delivered-title">What we've delivered</h2>
            <p>{item.whatDelivered.mainText}</p>
            <div>
              {item.whatDelivered.list &&
                item.whatDelivered.list.map((e) => <p>{e}</p>)}
            </div>
          </div>
          <div className="stacks__used">
            <h2 className="stacks__used-title">Stacks used</h2>
            <p>{item.stacksUsed}</p>
          </div>
          <div className="resources__used">
            <h2 className="resources__used-title">Resources used</h2>
            <p>{item.resourcesUsed}</p>
          </div>
          <div className="app-body">
            <a href={item.appForIOS} className="app" target="_blanck">
              delivered app for iOS
            </a>
            {item.appForAndroid && (
              <a href={item.appForAndroid} className="app">
                delivered app for android
              </a>
            )}
          </div>
        </div>
      </div>
      <Footersec />
    </div>
  );
};
