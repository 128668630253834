import React from "react";
import "./HomePage.css";
import MainSection from "../../components/home/MainSection/MainSection";
import Content2 from "../../components/home/content2/Expertise";
import Information from "../../components/home/Information/Information";
import Whatwedo from "../../components/home/Whatwedo/Whatwedo";
import StudyplanSec from "../../components/home/StudyplanSec/StudyplanSec";
import Ourphoto from "../../components/home/Ourphoto/Ourphoto";
import Footersec from "../../components/home/Footersec/Footersec";
import CompanyStaff from "../../components/home/CompanyStaff/CompanyStaff";
import OurProjects from "../../components/home/OurProjects/OurProjects";
import Header from "../../components/home/header/header";
import About from "../../components/home/About";

// import { Carousel } from React

const HomePage = () => {
  return (
    <div className="homepage">
      <Header />
      <MainSection />
      {/* <Content2 /> */}
      <About />
      <Information />
      {/* <Whatwedo />
      <StudyplanSec /> */}
      {/* <Ourphoto /> */}
      <CompanyStaff />
      <OurProjects />
      <Footersec />
    </div>
  );
};

export default HomePage;
