import logo from "./logo.svg";
import "./App.css";
import { Header } from "./components/home/header/header";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./containers/HomePage/HomePage";
import Custom from "./components/Custom/Custom";
import { Project } from "./components/home/OurProjects/Project";
import ScrollToTop from "./components/ScrollToTop";
import { PrivacyTerms } from "./components/home/PrivacyTerms/PrivacyTerms";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      {/* <HomePage /> */}

      {/* <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/:project" element={<Project />}></Route>
        <Route path="/profile" element={<div>profile</div>}></Route>

        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes> */}
      {/* <Custom /> */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:project" element={<Project />} />
        <Route path="/privacy-and-terms/" element={<PrivacyTerms />} />
      </Routes>
      <ScrollToTop />
    </div>
  );
}

export default App;
