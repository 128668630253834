import React from "react";
import "./CompanyStaff.css";
import data from "../../../data/data";
import Person from "./Person";

const CompanyStaff = () => {
  // console.log("datas", data);

  return (
    <div id="staff">
      <h2 className="staffTitle">Meet our team</h2>
      <div className="person">
        {data.map((person) => {
          return <Person key={person.id} {...person} />;
        })}
      </div>
    </div>
  );
};

export default CompanyStaff;
