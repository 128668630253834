import React from "react";
import "./Footersec.css";
import { Link } from "react-router-dom";

// import { BrowserRouter as Router, Link } from "react-router-dom";

const footer = [
  {
    id: "Instagram",
    // img: require("../../../assets/images/telegram_logo.svg"),
    img: require("../../../assets/images/instagram_black.png"),
    link: "https://instagram.com/jaiyqdev?igshid=ZjA0NjI3M2I=",
  },
  {
    id: "Telegram",
    img: require("../../../assets/images/telegram_logo.png"),
    link: "https://t.me/nrlnv",
  },
  {
    id: "Linkedin",
    img: require("../../../assets/images/linkedin_network.png"),
    link: "https://www.linkedin.com/company/jaiyq-development/about/",
  },
];

const visit = [
  {
    id: "Oral kalasy, Eskalieva 179",
    link: "https://go.2gis.com/4qelvi",
  },
  // {
  //   id: "nalco.tm.kz@mail.ru",
  //   link: "https://2gis.kz/uralsk/firm/70000001033339276",
  // },
];

const Footersec = () => {
  return (
    <footer className="main-footer">
      <div className="main-footer-block">
        <h4 className="tag">You find us</h4>
        <div>
          <ul className="footer-link">
            {footer.map((el) => {
              return (
                <a href={el.link}>
                  <li className="footer-link-item">
                    <img className="footer-link-icon" src={el.img} />
                  </li>
                  {/* <li>{el.img}</li> */}
                </a>
              );
            })}
          </ul>
        </div>
      </div>
      <div>
        <h4 className="tag">Visit</h4>
        <div className="main-footer-block">
          <ul className="footer-block-item">
            {visit.map((el) => {
              return (
                <>
                  <a href={el.link}>
                    <li>{el.id}</li>
                  </a>
                  <li>
                    <Link to="/privacy-and-terms">Privacy & Terms</Link>
                  </li>
                </>
              );
            })}
            {/* <li>Oral kalasy oky ortaligy</li>
          <li>nalco.tm.kz@mail.ru</li> */}
          </ul>
        </div>
      </div>
      {/* <div className="footer-telegram-icon">
        <a href="https://telegram.org/">
          <button className="footer-button">
            <img
              src={require("../../../assets/images/telegram.png")}
              alt="no footer photo"
            />
            <span>Napiwi nam pasan</span>
          </button>
        </a>
      </div> */}
      <img
        className="footer-jd-icon footer-icon"
        src={require("../../../assets/images/logo.png")}
        alt="no footer photo"
      />
    </footer>
  );
};

export default Footersec;
