import React from "react";
import projects from "../../../data/projects.jsx";
import "./OurProjects.css";
import { Link } from "react-router-dom";

function OurProjects() {
  return (
    <div id="projects" className="projects">
      <h2 className="projects__title">Our Projects</h2>
      <div className="projects__inner">
        {projects.map((el) => {
          return (
            <Link key={el.id} to={el.project} className="projects__inner-item">
              <img
                className="projects__img"
                // src={require("../../../assets/images/test-img-1.webp")}
                src={el.img}
                alt=""
              />
              <h3 className="projects-inner__title">{el.project}</h3>
              <p className="projects-inner__text">{el.whatOurClientDo}</p>
            </Link>
          );
        })}
      </div>
      <div></div>
    </div>
  );
}

export default OurProjects;
