import React from 'react';
import './CompanyStaff.css';
import photos from '../../../assets/images/Kenes.jpeg';

const Person = (props) => {
  const { id, name, lname, position, skills, photo } = props;
  console.log('photos', photos);
  //   const cabinet = photo;
  return (
    <div className='personWidth'>
      <img src={photo} alt="no photo" className='photo'/>
      <p>
        {name} {lname}
      </p>
      <p>{position}</p>
      <p>Main stack:<br/>{skills}</p>
    </div>
  );
};

export default Person;
