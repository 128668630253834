// const projects = [
//   {
//     id: 1,
//     img: require("../assets/testImg/test-img-1.webp"),
//     project: "Nam consequatur possimus et minus sunt.",
//     client: "Semper",
//     country: "China",
//     industry: "Landowner",
//     typeOfService:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
//     whatOurClientDo: "Arcu cursus vitae congue mauris rhoncus aenean vel elit.",
//     whatChallengedToDo: "Consequat semper viverra nam libero justo.",
//     whatDone: "Nullam ac tortor vitae purus faucibus ornare suspendisse. ",
//     whatDelivered: "Enim diam vulputate ut pharetra sit amet.",
//     stacks:
//       "Donec et odio pellentesque diam volutpat commodo sed egestas egestas.",
//     resources: "Congue quisque egestas diam in arcu cursus.",
//     duration: "Mi quis hendrerit dolor magna eget est lorem.",
//     deliveredFor:
//       "Phasellus vestibulum lorem sed risus ultricies tristique nulla aliquet.",
//   },
//   {
//     id: 2,
//     img: require("../assets/testImg/test-img-2.webp"),
//     project: "Non voluptatum fuga sit quia quas a impedit neque.",
//     client: "Risus",
//     country: "India",
//     industry: "Lift engineer",
//     typeOfService:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
//     whatOurClientDo: "Arcu cursus vitae congue mauris rhoncus aenean vel elit.",
//     whatChallengedToDo: "Consequat semper viverra nam libero justo.",
//     whatDone: "Nullam ac tortor vitae purus faucibus ornare suspendisse. ",
//     whatDelivered: "Enim diam vulputate ut pharetra sit amet.",
//     stacks:
//       "Donec et odio pellentesque diam volutpat commodo sed egestas egestas.",
//     resources: "Congue quisque egestas diam in arcu cursus.",
//     duration: "Mi quis hendrerit dolor magna eget est lorem.",
//     deliveredFor:
//       "Phasellus vestibulum lorem sed risus ultricies tristique nulla aliquet.",
//   },
//   {
//     id: 3,
//     img: require("../assets/testImg/test-img-3.webp"),
//     project: "Id provident galisum et quia facere.",
//     client: "Hendrerit",
//     country: "USA",
//     industry: "Party planner",
//     typeOfService:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
//     whatOurClientDo: "Arcu cursus vitae congue mauris rhoncus aenean vel elit.",
//     whatChallengedToDo: "Consequat semper viverra nam libero justo.",
//     whatDone: "Nullam ac tortor vitae purus faucibus ornare suspendisse. ",
//     whatDelivered: "Enim diam vulputate ut pharetra sit amet.",
//     stacks:
//       "Donec et odio pellentesque diam volutpat commodo sed egestas egestas.",
//     resources: "Congue quisque egestas diam in arcu cursus.",
//     duration: "Mi quis hendrerit dolor magna eget est lorem.",
//     deliveredFor:
//       "Phasellus vestibulum lorem sed risus ultricies tristique nulla aliquet.",
//   },
//   {
//     id: 4,
//     img: require("../assets/testImg/test-img-4.png"),
//     project:
//       "Id temporibus voluptate sit optio aliquid sit inventore expedita.",
//     client: "Gravida",
//     country: "Indonesia",
//     industry: "Postal delivery worker",
//     typeOfService:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
//     whatOurClientDo: "Arcu cursus vitae congue mauris rhoncus aenean vel elit.",
//     whatChallengedToDo: "Consequat semper viverra nam libero justo.",
//     whatDone: "Nullam ac tortor vitae purus faucibus ornare suspendisse. ",
//     whatDelivered: "Enim diam vulputate ut pharetra sit amet.",
//     stacks:
//       "Donec et odio pellentesque diam volutpat commodo sed egestas egestas.",
//     resources: "Congue quisque egestas diam in arcu cursus.",
//     duration: "Mi quis hendrerit dolor magna eget est lorem.",
//     deliveredFor:
//       "Phasellus vestibulum lorem sed risus ultricies tristique nulla aliquet.",
//   },
//   {
//     id: 5,
//     img: require("../assets/testImg/test-img-5.webp"),
//     project:
//       "Et dolorum praesentium eos praesentium aliquam eos neque expedita.",
//     client: "Rutrum",
//     country: "Pakistan",
//     industry: "Home-worker",
//     typeOfService:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
//     whatOurClientDo: "Arcu cursus vitae congue mauris rhoncus aenean vel elit.",
//     whatChallengedToDo: "Consequat semper viverra nam libero justo.",
//     whatDone: "Nullam ac tortor vitae purus faucibus ornare suspendisse. ",
//     whatDelivered: "Enim diam vulputate ut pharetra sit amet.",
//     stacks:
//       "Donec et odio pellentesque diam volutpat commodo sed egestas egestas.",
//     resources: "Congue quisque egestas diam in arcu cursus.",
//     duration: "Mi quis hendrerit dolor magna eget est lorem.",
//     deliveredFor:
//       "Phasellus vestibulum lorem sed risus ultricies tristique nulla aliquet.",
//   },
//   {
//     id: 6,
//     img: require("../assets/testImg/test-img-6.png"),
//     project: "Qui temporibus distinctio et omnis error non tenetur voluptas!",
//     client: "Pulvinar",
//     country: "Brazil",
//     industry: "Business consultant",
//     typeOfService:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
//     whatOurClientDo: "Arcu cursus vitae congue mauris rhoncus aenean vel elit.",
//     whatChallengedToDo: "Consequat semper viverra nam libero justo.",
//     whatDone: "Nullam ac tortor vitae purus faucibus ornare suspendisse. ",
//     whatDelivered: "Enim diam vulputate ut pharetra sit amet.",
//     stacks:
//       "Donec et odio pellentesque diam volutpat commodo sed egestas egestas.",
//     resources: "Congue quisque egestas diam in arcu cursus.",
//     duration: "Mi quis hendrerit dolor magna eget est lorem.",
//     deliveredFor:
//       "Phasellus vestibulum lorem sed risus ultricies tristique nulla aliquet.",
//   },
// ];
// export default projects;

const projects = [
  {
    id: 1,
    img: require("../assets/projects/project-1.png"),
    project: "Shipper",
    client: "Shipper",
    country: "USA/Kazakhstan",
    industry: "Shopping, delivery",
    typeOfService: "Outsource",
    whatOurClientDo:
      "Shipper service delivers purchases from foreign online stores to your door",
    whatChallengedToDo: {
      mainText:
        "Our task was to create an application for IOS and Android and Web where user can:",
      list: [
        "- create parcel",
        "- check parcel details, checkpoints",
        "- add balance with card payment, apple pay",
      ],
    },
    whatDone: {
      mainText: "The process was as follows:",
      list: [
        "1. Analysis of the project scope, preparation of a plan for development",
        "2. Determining the team members responsibilities",
        "3. Implementation of design",
        "4. Multiplatform app launch",
      ],
    },
    whatDelivered: {
      mainText:
        "Workshops, building a roadmap (2 weeks). Building MVP (3.5 months). Collecting feedback from first clients, vendors and Shipper back office team (2 months). Further product development based on collected data. The app is easy to use and delivers a fast, smooth experience across mobile and tablet devices. Thanks to our work, Shipper users can search for parcels they need, see details of checkpoints, and add balance using card/Apple pay.",
      // list: "",
    },
    stacksUsed:
      "Mobile App Development, Web Development, React Native, React, Redux-toolkit, REST API, Firebase, Push notifications, Deep linking, Payment with card, Apple pay, CloudPayments",
    resourcesUsed:
      "2 Mobile developers, 1 Project Manager, 1 Manual tester, 1 Web/Mobile designer",
    duration: "7+ months",
    appForIOS: "https://apps.apple.com/bg/app/shipper/id1597656265",
    appForAndroid: "",
  },
  {
    id: 2,
    img: require("../assets/projects/project-2.png"),
    project: "Earnity Mobile",
    client: "Earnity",
    country: "USA",
    industry: "Crypto, trading",
    typeOfService: "Outstaff",
    whatOurClientDo: "Crypto, trading, social network for traders",
    whatChallengedToDo: {
      mainText:
        "Earnity already had a fully functional web application. Due to market changes and users’ needs, the client sought to add a mobile app to the stack, both for Android and iOS platforms. As current users were familiar with the web app, the major challenge of the project was to deliver the same features and experience on the mobile version. The proposed app should be fast, scalable, and efficiently integrate with the API provided by the client. The client came to us with the final design and functional requirements but did not have much experience in the software development process.",
      // list: [
      //   "- create parcel",
      //   "- check parcel details, checkpoints",
      //   "- add balance with card payment, apple pay",
      // ],
    },
    whatDone: {
      mainText: "The process was as follows:",
      list: [
        "1. Analysis of project scope, preparation of a plan for development and design ",
        "2. Determining the team members responsibilities",
        "3. Implementation of design",
        "4. Starting developement process",
      ],
    },
    whatDelivered: {
      mainText:
        "After launching Earnity here are most interesting features in our opinion:",
      list: [
        "- user id verification using Jumio SDK",
        "- payment of tokens using Stripe payment system",
        "- social network, activity feeds and chat apps using GetStream.io",
        "- push notifications",
      ],
    },
    stacksUsed:
      "Mobile App Development, React Native, React Sweet state, getstream.io, Jumio SDK, Stripe SDK",
    resourcesUsed: "1 React Native developer",
    duration: "11+ months",
    appForIOS:
      "https://apps.apple.com/us/app/earnity-crypto-together/id1606059158",
    appForAndroid: "",
  },
  {
    id: 3,
    img: require("../assets/projects/project-3.png"),
    project: "Supermom Academy",
    client: "Vienna LLC",
    country: "Kazakhstan",
    industry: "Medical",
    typeOfService: "Outsource",
    whatOurClientDo:
      "The application is designed for pregnant women and mothers of children under the age of one year.",
    whatChallengedToDo: {
      mainText:
        "Our task was to create an application for Android and iOS where user can:",
      list: [
        "- have a online chat/video call with their attached doctor from clinic.",
        "- social network where user can share their knowledge and post some interesting news",
        "- health test",
      ],
    },
    whatDone: {
      mainText: "The process was as follows:",
      list: [
        "1. Analysis of project scope, preparation of a plan for development and design",
        "2. Determining the team members responsibilities",
        "3. Implementation of design",
        "4. Starting developement process",
      ],
    },
    whatDelivered: {
      mainText:
        "Over the course of eight months, we created Supermom Academy - a cross-platform application that easily can call alarm for ambulance. The user can have a chat/video call with avaialble doctor. Thanks to the application, user can read posts and share, like, comment them.",
      // list: "Thanks to the application, user can read posts and share, like, comment them.",
    },
    stacksUsed: "Mobile App Development, React Native",
    resourcesUsed:
      "1 Mobile developer, 1 Tester, 1 Project Manager, 1 Backend developer",
    duration: "8 months",
    appForIOS: "https://apps.apple.com/kz/app/supermom-academy/id1466733408",
    appForAndroid:
      "https://play.google.com/store/apps/details?id=com.vienna.mypregnancy",
  },
  {
    id: 4,
    img: require("../assets/projects/project-4.png"),
    project: "Kassa 24 Business",
    client: "Lichnaya Kassa, TOO",
    country: "Kazakhstan",
    industry: "Business",
    typeOfService: "Outstaff",
    whatOurClientDo:
      "Solutions for business: Online checkout, QR payments, delivery to parcel terminals, etc.",
    whatChallengedToDo: {
      mainText:
        "Our task was to create an application for Android and iOS which acts as cash register, where business can: make payments, return payments, QR payments, print receipts via bluetooth device, check payment history and etc.",
      // list: [
      //   "- create parcel",
      //   "- check parcel details, checkpoints",
      //   "- add balance with card payment, apple pay",
      // ],
    },
    whatDone: {
      // mainText: 'The process was as follows:',
      // list: [
      //   '1. Analysis of the project scope, preparation of a plan for development',
      //   '2. Determining the team members responsibilities',
      //   '3. Implementation of design',
      //   '4. Multiplatform app launch'
      // ]
    },
    whatDelivered: {
      mainText:
        "On the outcome we have mobile cash register. Business can easily accept payment, return payment if needed, print out receipts via external bluetooth printer, generate daily/weekly/monthly reports.",
    },
    stacksUsed:
      "Mobile App Development using React Native, Typescript, Redux tollkit, REST API, Firebase, Push notifications, Deep linking",
    resourcesUsed: "1 React Native developer",
    duration: "18+ months",
    appForIOS: "https://apps.apple.com/kz/app/kassa24-business/id1558115148",
    appForAndroid:
      "https://play.google.com/store/apps/details?id=kz.kassa24.business",
  },
  {
    id: 5,
    img: require("../assets/projects/project-5.png"),
    project: "Culdesac Sports",
    client: "Culdesac LLC",
    country: "USA",
    industry: "Sports",
    typeOfService: "Outstaff",
    whatOurClientDo:
      "Culdesac is a sports social media app designed to give the fans their voice back.",
    whatChallengedToDo: {
      mainText:
        "The main task was to make app like reddit, where users can publish posts on sports events, debate on other posts, comments, agrees and disagrees with posts. ",
      // list: [
      //   "- create parcel",
      //   "- check parcel details, checkpoints",
      //   "- add balance with card payment, apple pay",
      // ],
    },
    whatDone: {
      // mainText: 'The process was as follows:',
      // list: [
      //   '1. Analysis of the project scope, preparation of a plan for development',
      //   '2. Determining the team members responsibilities',
      //   '3. Implementation of design',
      //   '4. Multiplatform app launch'
      // ]
    },
    whatDelivered: {
      mainText:
        "On the outcome we have mobile app for journalists, ex-athlete or just average fan. Users can like, comment, agree, disagree with posts. Most liked posts appear in populars list.",
    },
    stacksUsed:
      "Mobile App Development using React Native, Typescript, Redux tollkit, REST API, Firebase, Push notifications, Deep linking",
    resourcesUsed: "1 React Native developer",
    duration: "5 months",
    appForIOS: "https://apps.apple.com/kz/app/culdesac-sports/id1591063411",
    appForAndroid: "",
  },
  {
    id: 6,
    img: require("../assets/projects/project-6.png"),
    project: "Exec Benefits",
    client: "Exec LLC",
    country: "USA",
    industry: "Business",
    typeOfService: "Outstaff",
    whatOurClientDo:
      "Highly curated benefits program designed to improve life of the busy executive.",
    whatChallengedToDo: {
      mainText:
        "The task was to create IOS/Android app where users can use variety of benefits. ",
      // list: [
      //   "- create parcel",
      //   "- check parcel details, checkpoints",
      //   "- add balance with card payment, apple pay",
      // ],
    },
    whatDone: {
      // mainText: 'The process was as follows:',
      // list: [
      //   '1. Analysis of the project scope, preparation of a plan for development',
      //   '2. Determining the team members responsibilities',
      //   '3. Implementation of design',
      //   '4. Multiplatform app launch'
      // ]
    },
    whatDelivered: {
      mainText:
        "After launching app EXEC users can unlocks access, heightened levels of hospitality, and negotiated pricing through an exclusive collection of hundreds of benefits. EXEC helps people upgrade their life in ways that were previously unattainable.",
    },
    stacksUsed:
      "Mobile App Development using React Native, Typescript, Redux tollkit, Graphql, Firebase, Push notifications, Deep linking",
    resourcesUsed: "1 React Native developer",
    duration: "6-8 months",
    appForIOS: "https://apps.apple.com/kz/app/exec-benefits/id1571605264",
    appForAndroid: "https://play.google.com/store/apps/details?id=com.exec",
  },
  {
    id: 7,
    img: require("../assets/projects/project-7.png"),
    project: "NB44",
    client: "NB44 LLC",
    country: "USA",
    industry: "Fashion design",
    typeOfService: "Outstaff",
    whatOurClientDo:
      "Membership-based luxury brand that designs, styles, and delivers the world's highest quality menswear and accessories",
    whatChallengedToDo: {
      mainText:
        "The main challenge of the app is to implement animated design requested by client. Another task is to notify user on every step of collection delivery.",
      // list: [
      //   "- create parcel",
      //   "- check parcel details, checkpoints",
      //   "- add balance with card payment, apple pay",
      // ],
    },
    whatDone: {
      // mainText: 'The process was as follows:',
      // list: [
      //   '1. Analysis of the project scope, preparation of a plan for development',
      //   '2. Determining the team members responsibilities',
      //   '3. Implementation of design',
      //   '4. Multiplatform app launch'
      // ]
    },
    whatDelivered: {
      mainText:
        "On the outcome we have beautiful cross platform app. Users can save their credit cards in the app, choose the default one, and make payments for membership.",
    },
    stacksUsed:
      "Mobile App Development using React Native, Typescript, Redux tollkit, REST API, Firebase, Push notifications, Deep linking",
    resourcesUsed: "1 React Native developer",
    duration: "8+ months",
    appForIOS: "https://apps.apple.com/kz/app/nb44/id1631024387",
    appForAndroid: "https://play.google.com/store/apps/details?id=com.nb44",
  },
];

export default projects;
