import React from "react";
import "./style.css";
import Footersec from "../Footersec/Footersec";
import { Link } from "react-router-dom";

export const PrivacyTerms = () => {
  return (
    <>
      <Link to="/" className="btn-back">
        <svg
          className="btn-svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          height="30px"
          width="30px"
        >
          <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.42L6.83 13H21v-2z" />
        </svg>
      </Link>
      <div className="privacyTersm">
        <h1 className="privacyTitle">
          JAIYQDEV LLC Privacy Policy and Terms of Service
        </h1>
        <div className="privacyInner">
          <h2>1. Terms</h2>
          <p>
            By accessing the website at gojilabs.com, you are agreeing to be
            bound by these terms of service, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this site. The
            materials contained in this website are protected by applicable
            copyright and trademark law.
          </p>
          <h2>2. Use License</h2>
          <div className="privacyInner__item">
            <p>a.</p>
            <ul className="list__items">
              <p>
                Permission is granted to temporarily download one copy of the
                materials (information or software) on JAIYQDEV LLC’s website
                for personal, non-commercial transitory viewing only. This is
                the grant of a license, not a transfer of title, and under this
                license you may not:
              </p>
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained
                on JAIYQDEV LLC’s website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or “mirror” the
                materials on any other server.
              </li>
            </ul>
          </div>

          <div className="privacyInner__item">
            <p>b.</p>
            <p>
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by JAIYQDEV LLC at any
              time. Upon terminating your viewing of these materials or upon the
              termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </p>
          </div>
          <h2>3. Disclaimer</h2>
          <div className="privacyInner__item">
            <p>a.</p>
            <p>
              The materials on JAIYQDEV LLC’s website are provided on an ‘as is’
              basis. JAIYQDEV LLC makes no warranties, expressed or implied, and
              hereby disclaims and negates all other warranties including,
              without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of
              rights.
            </p>
          </div>

          <div className="privacyInner__item">
            <p>b.</p>
            <p>
              Further, JAIYQDEV LLC does not warrant or make any representations
              concerning the accuracy, likely results, or reliability of the use
              of the materials on its website or otherwise relating to such
              materials or on any sites linked to this site.
            </p>
          </div>
          <h2>4. Limitations</h2>
          <p>
            In no event shall JAIYQDEV LLC or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption) arising out of the use or
            inability to use the materials on JAIYQDEV LLC’s website, even if
            JAIYQDEV LLC or a JAIYQDEV LLC authorized representative has been
            notified orally or in writing of the possibility of such damage.
            Because some jurisdictions do not allow limitations on implied
            warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you.
          </p>
          <h2>5. Accuracy of materials</h2>
          <p>
            The materials appearing on JAIYQDEV LLC’s website could include
            technical, typographical, or photographic errors. JAIYQDEV LLC does
            not warrant that any of the materials on its website are accurate,
            complete or current. JAIYQDEV LLC may make changes to the materials
            contained on its website at any time without notice. However
            JAIYQDEV LLC does not make any commitment to update the materials.
          </p>
          <h2>6. Links</h2>
          <p>
            JAIYQDEV LLC has not reviewed all of the sites linked to its website
            and is not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by JAIYQDEV LLC of
            the site. Use of any such linked website is at the user’s own risk.
          </p>
          <h2>7. Modifications</h2>
          <p>
            JAIYQDEV LLC may revise these terms of service for its website at
            any time without notice. By using this website you are agreeing to
            be bound by the then current version of these terms of service.
          </p>
          <h2>8. Privacy Policy</h2>
          <div>
            <p>
              Your privacy is important to us. It is JAIYQDEV LLC’s policy to
              respect your privacy regarding any information we may collect from
              you across our website and other sites we own and operate.
            </p>
            <p>
              We only ask for personal information when we truly need it to
              provide a service to you. We collect it by fair and lawful means,
              with your knowledge and consent. We also let you know why we’re
              collecting it and how it will be used.
            </p>
            <p>
              We only retain collected information for as long as necessary to
              provide you with your requested service. What data we store, we’ll
              protect within commercially acceptable means to prevent loss and
              theft, as well as unauthorised access, disclosure, copying, use or
              modification.
            </p>
            <p>
              We don’t share any personally identifying information publicly or
              with third-parties, except when required to by law.
            </p>
            <p>
              Our website may link to external sites that are not operated by
              us. Please be aware that we have no control over the content and
              practices of these sites, and cannot accept responsibility or
              liability for their respective privacy policies.
            </p>

            <p>
              You are free to refuse our request for your personal information,
              with the understanding that we may be unable to provide you with
              some of your desired services.
            </p>

            <p>
              Your continued use of our website will be regarded as acceptance
              of our practices around privacy and personal information. If you
              have any questions about how we handle user data and personal
              information, feel free to contact us.
            </p>

            <p>This policy is effective as of March 22, 2022.</p>
          </div>
        </div>
      </div>
      <Footersec />
    </>
  );
};
