import React, { useEffect, useState } from "react";

import "./styles.css";

const menu = [
  { text: "Services", href: "#services" },
  { text: "Expertise", href: "#expertise" },
  { text: "Industries", href: "#industries" },
  // { text: 'Success stories', href: '#stories' },
  { text: "Our team", href: "#staff" },
  { text: "Our Projects", href: "#projects" },
  // { text: "Smart comfort", href: "#comfort" },
  // { text: "Social responsibility", href: "#res" },
];

export const Header = () => {
  const [isNavSticky, setNavPosition] = useState();

  const handleScrollEvent = () => {
    console.log("window.scrollY", window.scrollY);
    if (window.scrollY >= window.outerHeight - 155) {
      console.log("window.scrollY", window.scrollY);
      console.log("window.outerHeight", window.outerHeight);
      setNavPosition(true);
    } else {
      setNavPosition(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollEvent);
  });

  console.log("window", window.outerHeight);

  const logo = isNavSticky
    ? require("../../../assets/images/logo.png")
    : require("../../../assets/images/logo2.png");

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <header className={`App-header ${isNavSticky && "sticky"} `}>
      <div className="logon">
        <img src={logo} alt="no photo friend" />
      </div>
      <ul className="menu">
        {menu.map((el, index) => (
          <a
            key={index}
            style={{ color: isNavSticky ? "black" : "white " }}
            href={el.href}
          >
            <span>{el.text}</span>
          </a>
        ))}
      </ul>
      <div className="dropdown" onMouseLeave={handleOpen} onScroll={handleOpen}>
        <button
          onClick={handleOpen}
          style={{
            backgroundColor: "transparent",
            color: isNavSticky ? "black" : "white ",
            height: 50,
          }}
        >
          <span
            style={{
              backgroundColor: "transparent",
              color: isNavSticky ? "black" : "white ",
              height: 50,
            }}
          >
            {/* Navigation */}
            <svg
              viewBox="0 0 100 80"
              width="65"
              height="40"
              fill={isNavSticky ? "black" : "white "}
              // style={{ color: 'red', backgroundColor: 'green' }}
            >
              <rect y="20" width="100" height="10"></rect>
              <rect y="40" width="100" height="10"></rect>
              <rect y="60" width="100" height="10"></rect>
            </svg>
          </span>
        </button>
        {open ? (
          <ul className="menu-drop">
            {menu.map((el, index) => (
              <a
                className="menu-item"
                key={index}
                style={{
                  color: "white ",
                  display: "flex",
                  flexDirection: "column",
                }}
                href={el.href}
              >
                <span onClick={handleOpen}>{el.text}</span>
              </a>
            ))}
          </ul>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
